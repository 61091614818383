
import { defineComponent, reactive, onMounted } from "vue";

import BannerClassify from "@/components/common/BannerClassify.vue";
import ProductInfo from "@/components/product/ProductInfo.vue";
import FooterInfo from "@/components/common/FooterInfo.vue";
import CopyrightInfo from "@/components/common/CopyrightInfo.vue";
import MyPagination from "@/components/common/MyPagination.vue";

import { getListApi, getCategorysApi,getBannerByIdApi } from "@/api/api";
import { baseApiUrl } from "@/config/apiConfig";
interface ClassifyInfo {
  code: string;
  label: string;
}

export default defineComponent({
  name: "Product",
  components: {
    BannerClassify,
    ProductInfo,
    FooterInfo,
    CopyrightInfo,
    MyPagination,
  },
  setup() {
    const state = reactive<{
      bannerUrl: string;
      activeCode: string;
      activetype: number;
      classifyList: ClassifyInfo[];
      list: any[];
      path: any;
      arr: any[];
      page: {
        current: number;
        size: number;
      };
      total: number;
    }>({
      bannerUrl:
        "@/assets/img/scheme_bg.png",
      activeCode: "1",
      activetype: 5,
      classifyList: [
        // {
        //   code: "1",
        //   label: "产品方案",
        // },
      ],
      list: [],
      path: "",
      arr: [],
      page: {
        current: 1,
        size: 10,
      },
      total: 0,
    });

    onMounted(() => {
      state.path = baseApiUrl;
      getCategorys();
      getBannerById()
    });
    const getBannerById = async () => {
      let res = await getBannerByIdApi(5)
      if (res.data.rows && res.data.rows.length) {
        state.bannerUrl = 'https://gwht.scrzhy.com' + res.data.rows[0].savePath
      }
    }
    const getCategorys = async () => {
      try {
        const result = await getCategorysApi();
        if (result.status !== 200 || !result.data) return false;
        const { code, msg, data } = result.data;
        if (data && data.length) {
          const arr = data.find(
            (item: any) => item.categoryName === "产品方案"
          ).nodes;
          if (arr && arr.length) {
            state.classifyList = arr.map((item: any) => {
              return {
                code: item.categoryId.toString(),
                label: item.categoryName,
              };
            });
            state.activeCode = state.classifyList[0].code;
            getNews({
              articleModel: 5,
              pageNum: state.page.current,
              pageSize: state.page.size,
              categoryId: Number(state.activeCode),
            });
          }
        }
      } catch (e) {}
    };

    const changePage = (val: any) => {
      state.page.current = val.currentPage;
      getNews({
        articleModel: 5,
        pageNum: state.page.current,
        pageSize: state.page.size,
        categoryId: Number(state.activeCode),
      });
      // state.list=state.arr.slice(state.page.current*10-10,state.page.current*10+10)
    };
    const changeActiveCode = (activeCode: string) => {
      state.activeCode = activeCode;
      getNews({
        articleModel: 5,
        pageNum: state.page.current,
        pageSize: state.page.size,
        categoryId: Number(state.activeCode),
      });
    };

    const getNews = async (params: {
      articleModel: number;
      pageNum?: number;
      pageSize?: number;
      categoryId?: number;
    }) => {
      try {
        const result = await getListApi(params);
        if (result.status !== 200 || !result.data) return false;
        const { code, msg, data } = result.data;
        if (code !== 0) {
          console.log("报错了", msg);
          return false;
        }
        state.total = data.total;
        state.list = data.rows.map((item: any) => {
          return {
            id: item.id,
            title: item.title,
            description: item.description
              ? item.description.substring(0, 120) + "..."
              : item.description,
            coverImage: state.path + item.coverImage,
            createTime: item.createTime
              ? item.createTime.split(" ")[0]
              : item.createTime,
          };
        });

        //  state.list=state.arr.slice(0,10)
      } catch (e) {
        console.log("出错", e);
      }
    };

    return {
      changePage,
      state,
      changeActiveCode,
    };
  },
});
