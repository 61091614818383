import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6eba0fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerClassify = _resolveComponent("BannerClassify")!
  const _component_ProductInfo = _resolveComponent("ProductInfo")!
  const _component_MyPagination = _resolveComponent("MyPagination")!
  const _component_FooterInfo = _resolveComponent("FooterInfo")!
  const _component_CopyrightInfo = _resolveComponent("CopyrightInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BannerClassify, {
      type: "5",
      style: {"margin-top":"90px"},
      bannerUrl: _ctx.state.bannerUrl,
      activeCode: _ctx.state.activeCode,
      classifyList: _ctx.state.classifyList,
      onOnChangeActiveCode: _ctx.changeActiveCode
    }, null, 8, ["bannerUrl", "activeCode", "classifyList", "onOnChangeActiveCode"]),
    _createVNode(_component_ProductInfo, {
      list: _ctx.state.list,
      activetype: _ctx.state.activetype
    }, null, 8, ["list", "activetype"]),
    _createVNode(_component_MyPagination, {
      totalCount: _ctx.state.total,
      currentPage: _ctx.state.page.current,
      onOnChangePagination: _ctx.changePage
    }, null, 8, ["totalCount", "currentPage", "onOnChangePagination"]),
    _createVNode(_component_FooterInfo),
    _createVNode(_component_CopyrightInfo)
  ]))
}