
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "ProductInfo",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    activetype: {
      type: Number,
      default: 5,
    },
  },
  setup(props) {
    const router = useRouter();

    const handleGoDetail = (data: any) => {
      if (!data || !data.id) return false;
      router.push({
        path: "/detail",
        query: {
          id: data.id,
          type: props.activetype,
        },
      });
    };

    return {
      handleGoDetail,
    };
  },
});
